import {Injectable, TemplateRef} from '@angular/core';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  openErrorDialog(message: string, errorCode: number, errMessage: string) {
    const initialState = {
      errorCode: errorCode,
      message: message,
      errMessage: errMessage,
      title: 'An error has occurred!'
    };
    this.bsModalRef = this.modalService.show(ErrorDialogComponent, {initialState, class: 'modal-dialog-centered'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
