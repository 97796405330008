import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {ErrorDialogService} from '../../errors/error-dialog.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private errorDialogService: ErrorDialogService,
  ) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorDialogService.openErrorDialog(error.message, error.status, error.error.message);
        console.log('HttpErrorInterceptor ... ', error);
        return throwError(error);
      }),
      finalize(() => {
        console.log('finalize ... ');
      })
    ) as Observable<HttpEvent<any>>;
  }
}
