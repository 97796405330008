import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  currentUser: any = null;
  isAuth: Boolean = false;
  expirationAccessToken: string = null;
  userInfos: any = null;
  expToken;

  constructor(private httpClient?: HttpClient,
              private route?: Router) {
  }

  async signOut() {
    try {
      this.isAuth = false;
      await Auth.signOut();
      this.route.navigateByUrl('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async checkAuth() {
    try {
      this.currentUser = await Auth.currentAuthenticatedUser();
      this.isAuth = true;
      console.log(this.currentUser);
    } catch (error) {
      this.isAuth = false;
    }
  }

  setUserInfos(userInfos: any) {
    this.userInfos = userInfos;
  }

  getUserInfos() {
    return this.userInfos;
  }


  getExpirationToken() {
    const exp_time = this.currentUser.signInUserSession.accessToken.payload.exp;
    const exp = exp_time * 1000;
    const dateExp = new Date(exp);
    const now = new Date();
    this.expToken = ((dateExp.getTime() - now.getTime()));
    console.log(this.expToken);
  }

  getCurrentUserRoles(): string[] {
    const userAccessToken = this.getDecodedAccessToken(this.currentUser?.signInUserSession.accessToken.jwtToken);
    if (isNotNullOrUndefined(userAccessToken)) {
      const userRoles = userAccessToken['cognito:groups'];
      return userRoles;
    }
    return [];

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  isManagerOrAdmin() {
    const userRoles = this.getCurrentUserRoles();
    for (const role of userRoles) {
      if (role.localeCompare('ADMIN') || role.localeCompare('MANAGER')) {
        return true;
      }
    }
    return false;
  }

  isUser() {
    const userRoles = this.getCurrentUserRoles();
    for (const role of userRoles) {
      if (role.localeCompare('USER')) {
        return true;
      }
    }
    return false;
  }
}
