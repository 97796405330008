<app-header

  class="bg-primary text-white"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo_conkeite.png', width: 75, height: 31, alt: 'ConKeite'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo_conkeite.png', width: 45, height: 25, alt: 'ConKeite'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false">
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item ml-2">
      <a class="text-muted">Aujourd'hui :
        <span class="ml-2">{{ today | date:'dd MMMM yyyy' }}</span>
      </a>
    </li>
  </ul>
  <ul class="nav  text-muted navbar ml-auto d-md-down-none">
    <span class="font-weight-bold">{{ today | date:'HH:mm:ss' }}</span>
  </ul>
  <ul class="nav navbar-nav ml-auto mr-3">
    <li class="nav-item d-md-down-none mr-3" hidden="true">
      <a class="nav-link">
        <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></div>
          <input class="form-control" id="username" name="username" placeholder="Rechercher..." type="text"></div>
      </a>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
         dropdownToggle (click)="false">
        <i class="icon-bell"></i><span class="badge badge-pill badge-danger">5</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Workflow</strong></div>
        <a class="dropdown-item"><i class="fa fa-code-fork"></i>Workflow 1<span
          class="badge badge-info">42</span></a>
        <div class="dropdown-header text-center"><strong>Dossiers</strong></div>
        <a class="dropdown-item"><i class="fa fa-folder"></i>Dossier 1<span
          class="badge badge-info">12</span></a>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"
         dropdownToggle (click)="false">
        <img src="assets/img/avatars/default-avatar.jpg" class="img-avatar" alt="admin@bootstrapmaster.com"/>
        <span class="font-weight-bold text-white">{{ usersInfos?.username }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Mon compte</strong></div>
        <a class="dropdown-item _pointer" (click)="redirectToProfile()"><i class="fa fa-user"></i>Profile</a>
        <a class="dropdown-item _pointer"><i class="fa fa-envelope-o"></i>Messages<span
          class="badge badge-primary">42</span></a>
        <a class="dropdown-item text-danger _pointer" (click)="onLogout()"><i class="fa fa-lock"></i>Déconnexion</a>
      </div>
    </li>
  </ul>
</app-header>
  <div class="app-body">
    <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
                 (minimizedChange)="toggleMinimize($event)">
      <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
      <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>
    <!-- Main content -->
    <main class="main bg-secondary">
      <!-- Breadcrumb -->
      <!-- breaking change 'cui-breadcrumb' -->
      <!--<cui-breadcrumb>
        &lt;!&ndash; Breadcrumb Menu&ndash;&gt;
        <li class="breadcrumb-menu d-md-down-none">
          <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
            <a class="btn"><i class="icon-speech"></i></a>
            <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;<span class="_hoverColor">Tableau de bord</span></a>
          </div>
        </li>
      </cui-breadcrumb>-->

      <!-- deprecation warning for 'app-breadcrumb' -->
      <!--<ol class="breadcrumb">-->
      <!--<app-breadcrumb></app-breadcrumb>-->
      <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
      <!--<li class="breadcrumb-menu d-md-down-none">-->
      <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
      <!--<a class="btn" ><i class="icon-speech"></i></a>-->
      <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
      <!--<a class="btn" ><i class="icon-settings"></i> &nbsp;Settings</a>-->
      <!--</div>-->
      <!--</li>-->
      <!--</ol>-->
        <router-outlet></router-outlet>
    </main>
  </div>
<app-footer>
  <div class="container text-center">
    <h6><img src="assets/img/brand/logo_conkeite.png"
             class="mt-1"
             style="width: 60px; height: 30px"/>- © 2021 Copyright</h6>
  </div>
</app-footer>
