export const environment = {
  production: false,
  baseURL: 'https://st-ecm-api.e-kaidi.net',
  amplify: {
    Auth: {
      mandatorySignIn: false,
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_ClQIA64xm',
      userPoolWebClientId: '6u2tr1lj215vlr6cm7c15656hu',
      authenticationFlowType: 'USER_SRP_AUTH'
    }
  }
};
