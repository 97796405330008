import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CognitoService} from './authentication-services/cognito.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private http: HttpClient,
              private cognitoService: CognitoService) {
  }

  ngOnInit() {
    this.cognitoService.checkAuth();
    this.storeNoConnectionIMG();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  storeNoConnectionIMG() {
    this.http.get('/assets/img/no_wifi.png', {responseType: 'blob'})
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          localStorage.setItem('no_wifi', base64data);
        };
        reader.readAsDataURL(res);
      });
  }

}
