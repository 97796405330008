<div class="row">
  <div class="col-5 ml-auto mr-auto mt-5">
    <div class="card text-center">
      <div class="card-header">
        <h3>404</h3>
      </div>
      <div class="card-body">
        <img [src]="image" class="img_size" alt="no connection">
      </div>
      <div class="card-footer">
        <h5>Vérifier votre connexion internet</h5>
      </div>
    </div>
  </div>
</div>
