import {Component, OnInit} from '@angular/core';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.css']
})
export class NoConnectionComponent implements OnInit {

  image;
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.image = localStorage.getItem('no_wifi');
  }

  goDashboard() {
    this.router.navigateByUrl('dashboard');
  }

}
