<div *ngIf="!isAuth">
  <div class="app-body full-height">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-5 mx-auto">
            <div *ngIf="showAlert" class="alert alert-info alert-dismissible fade show text-center" role="alert">
              <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>
              {{ SuccessMessage }}
              <button type="button" class="close" (click)="showAlert=!showAlert;">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div *ngIf="invalide" class="text-center alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ notAuthMessage }}</strong>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                      (click)="invalide = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="card">
              <div class="card-header text-center">
                <img src="assets/img/brand/logo_conkeite.png"
                     style="width: 80px; height: 40px"/>
              </div>
              <div class="card-body">
                <form *ngIf="!connected" #form="ngForm" (ngSubmit)="form.valid && login(form.value)" novalidate>
                  <p class="text-muted">Connectez-vous à votre compte</p>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="icon-user"></i></div>
                    </div>
                    <input type="text"
                           name="username"
                           id="username"
                           [(ngModel)]="credentials.username"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': (name.touched || form.submitted) && name.errors?.required }"
                           #name="ngModel"
                           placeholder="Nom d'utilisateur"
                           appValidateUserName
                           required>
                  </div>
                  <span class="text-danger font-col"
                        *ngIf="(name.touched || form.submitted) && name.errors?.required">
                                Nom d'utilisateur requis
                            </span>
                  <span class="text-danger font-col" *ngIf="name.touched && name.errors?.userNameNotAvailable">
                                Nom d'utilisateur n'est pas valide
                 </span>
                  <div class="input-group mt-3">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="icon-lock"></i></div>
                    </div>
                    <input type="password"
                           name="password"
                           id="password"
                           [(ngModel)]="credentials.password"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': ((password.touched || form.submitted) && password.errors?.required) || password.errors?.invalidPassword }"
                           #password="ngModel"
                           placeholder="Mot de passe"
                           appPasswordPattern
                           required>
                  </div>
                  <span class="text-danger font-col"
                        *ngIf="(password.touched || form.submitted) && password.errors?.required">
                                Le mot de passe est requis
                  </span>
                  <span class="text-danger font-col" *ngIf="password.touched && password.errors?.invalidPassword">
                           Le mot de passe doit contenir au moins, 8 caractères, 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et un symbole
                </span>
                  <div class="mt-4">
                    <button type="submit"
                            class="btn btn-primary btn-block"
                            [disabled]="loading">
                      <span class="icon-login mr-2"></span>
                      Connexion
                    </button>
                  </div>
                  <hr class="mt-4">
                  <div class="text-center">
                    <button type="button" (click)="openModal(forgotPass)" class="btn btn-link">Mot de passe oublié ?
                    </button>
                  </div>
                </form>
                <!-- form reset password -->
                <form *ngIf="connected" #formConf="ngForm" [appMatchPassword]="['newPassword', 'confNewPassword']"
                      (ngSubmit)="formConf.valid && resetPass(formConf.value)" novalidate>
                  <p class="text-muted">Veuillez choisir un nouveau mot de passe</p>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="icon-lock"></i></div>
                    </div>
                    <input type="password"
                           name="newPassword"
                           id="newPassword"
                           [(ngModel)]="resetPassword.newPassword"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': ((newPassword.touched || formConf.submitted) && newPassword.errors?.required) || newPassword.errors?.invalidPassword }"
                           #newPassword="ngModel"
                           placeholder="Nouveau mot de passe"
                           appPasswordPattern
                           required>
                  </div>
                  <span class="text-danger font-col"
                        *ngIf="(newPassword.touched || formConf.submitted) && newPassword.errors?.required">
                                Le mot de passe est requis
                  </span>
                  <span class="text-danger font-col" *ngIf="newPassword.touched && newPassword.errors?.invalidPassword">
                           Le mot de passe doit contenir au moins, 8 caractères, 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et un symbole
                </span>
                  <div class="input-group mt-3">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><i class="icon-lock"></i></div>
                    </div>
                    <input type="password"
                           name="confNewPassword"
                           id="confNewPassword"
                           [(ngModel)]="resetPassword.confNewPassword"
                           class="form-control"
                           [ngClass]="{ 'is-invalid': (confNewPassword.touched || formConf.submitted) && confNewPassword.errors?.required }"
                           #confNewPassword="ngModel"
                           required
                           placeholder="Confirmer votre mot de passe">
                  </div>
                  <span class="text-danger font-col"
                        *ngIf="(confNewPassword.touched || formConf.submitted) && confNewPassword.errors?.required">
                               Confirmer le mot de passe est requis
                            </span>
                  <span class="text-danger font-col"
                        *ngIf="confNewPassword.touched && confNewPassword.errors?.passwordMismatch">
                               Les mots de passe ne correspondent pas
                            </span>
                  <div class="row justify-content-center mt-3">
                    <div class="col-6">
                      <button (click)="connected=false;loading = false;" class="btn btn-light btn-md btn-block">
                        <i class="fa fa-minus-circle mr-2"></i>
                        Annuler
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="submit"
                              class="btn btn-primary btn-md btn-block"
                              [disabled]="loading">
                        <span class="fa fa-save mr-2"></span>
                        Confirmer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>
    <div class="footer" style="color: #2f353a">
      <h6><img src="assets/img/brand/logo_conkeite.png"
               class="mt-1"
               style="width: 65px; height: 31px"/>- © 2021 Copyright</h6>
    </div>
  </div>
  <ng-template #forgotPass>
    <div class="modal-header">
      <h5 class="modal-title pull-left">Mot de passe oublié</h5>
      <button type="button" class="close pull-right" aria-label="Close"
              (click)="modalRef.hide();forgotPassword.username='';userName=false;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="showAlertError" class="alert alert-danger alert-dismissible fade show text-center" role="alert">
        <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>
        {{ ErrorMessage }}
        <button type="button" class="close" (click)="showAlertError=!showAlertError;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #formForget="ngForm" [appMatchPassword]="['_newPassword', '_confNewPassword']"
            (ngSubmit)="formForget.valid && onChangePassword()" novalidate>
        <div *ngIf="!userName">
          <p>Veuillez entrer votre nom d'utilisateur</p>
          <div class="input-group mt-2">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="icon-lock"></i></div>
            </div>
            <input type="text"
                   name="userNamePass"
                   id="userNamePass"
                   [(ngModel)]="forgotPassword.username"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': (userNamePass.touched || formForget.submitted) && userNamePass.errors?.required }"
                   #userNamePass="ngModel"
                   placeholder="Nom d'utilisateur"
                   required>
          </div>
          <span class="text-danger font-col"
                *ngIf="(userNamePass.touched || formForget.submitted) && userNamePass.errors?.required">
                                Le nom d'utlisateur est requis<br>
        </span>
          <div class="row justify-content-center mt-3">
            <div class="col-6">
              <button type="button" (click)="modalRef.hide();_loading=false;forgotPassword.username=''"
                      class="btn btn-light btn-md btn-block">
                <i class="fa fa-minus-circle mr-2"></i>
                Fermer
              </button>
            </div>
            <div class="col-6 text-right">
              <button type="button"
                      class="btn btn-primary btn-md btn-block"
                      [disabled]="_loading || userNamePass.invalid"
                      (click)="onForgetPassword()">
                Suivant
                <i class="fa fa-long-arrow-right ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="userName">
          <p class="text-muted"> Veuillez entrer le code de vérification et le nouveau mot de passe</p>
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fa fa-key"></i></div>
            </div>
            <input type="text"
                   name="code"
                   id="code"
                   [(ngModel)]="forgotPassword.code"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': (code.touched || formForget.submitted) && code.errors?.required }"
                   #code="ngModel"
                   placeholder="Code de vérification"
                   required>
          </div>
          <span class="text-danger font-col"
                *ngIf="(code.touched || formForget.submitted) && code.errors?.required">
                                Le code est requis
                  </span>
          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="icon-lock"></i></div>
            </div>
            <input type="password"
                   name="_newPassword"
                   id="_newPassword"
                   [(ngModel)]="forgotPassword.newPassword"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': ((_newPassword.touched || formForget.submitted) && _newPassword.errors?.required) || _newPassword.errors?.invalidPassword }"
                   #_newPassword="ngModel"
                   required
                   appPasswordPattern
                   placeholder="Nouveau mot de passe">
          </div>
          <span class="text-danger font-col"
                *ngIf="(_newPassword.touched || formForget.submitted) && _newPassword.errors?.required">
                        Le mot de passe est requis
                </span>
          <span class="text-danger font-col"
                *ngIf="_newPassword.touched && _newPassword.errors?.invalidPassword">
                   Le mot de passe doit contenir au moins 8 caractères dont 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et un symbole
     </span>

          <div class="input-group mt-3">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="icon-lock"></i></div>
            </div>
            <input type="password"
                   name="_confNewPassword"
                   id="_confNewPassword"
                   [(ngModel)]="forgotPassword.confirmPassword"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': (_confNewPassword.touched || formForget.submitted) && _confNewPassword.errors?.required }"
                   #_confNewPassword="ngModel"
                   required
                   placeholder="Confirmer votre mot de passe">
          </div>
          <span class="text-danger font-col"
                *ngIf="(_confNewPassword.touched || formForget.submitted) && _confNewPassword.errors?.required">
                               Confirmer le mot de passe est requis
                            </span>
          <span class="text-danger font-col"
                *ngIf="_confNewPassword.touched && _confNewPassword.errors?.passwordMismatch">
                               Les mots de passe ne correspondent pas
                 </span>
          <div class="row justify-content-center mt-3">
            <div class="col-6">
              <button (click)="_loading=false;userName=false;forgotPassword.username=''"
                      class="btn btn-light btn-md btn-block">
                <i class="fa fa-long-arrow-left mr-2" aria-hidden="true"></i>
                Précédant
              </button>
            </div>
            <div class="col-6 text-right">
              <button type="submit"
                      class="btn btn-primary btn-md btn-block"
                      [disabled]="_loading">
                <span class="fa fa-save mr-2"></span>
                Confirmer
              </button>

            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
