<div class="modal-body">
  <h2>{{ title }}</h2>
  <div>
    <p>
      Something went wrong!
    </p>
    <p>
      {{errorCode}}
    </p>
    <p class="error-message">
      <span class="text-danger">{{errMessage}}</span><br>
      <span class="text-danger mt-2">{{message}}</span>
    </p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>

