import { Injectable } from '@angular/core';
import {fromEvent} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class NetworkService {

  online: boolean;
  isNetworkStopped = false;
  currentUrl = null;

  constructor(private router: Router) {
    this.online = window.navigator.onLine;

    fromEvent(window, 'online').subscribe(e => {
      this.online = true;
      this.router.navigateByUrl(this.currentUrl);
    });

    fromEvent(window, 'offline').subscribe(e => {
      this.currentUrl = this.router.url;
      this.online = false;
      this.router.navigateByUrl('noConnection');
    });
  }
}
