import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LogoutDialogComponent} from './error-dialog/logout-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LogoutDialogService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService
  ) {
  }

  openLogoutDialog() {
    this.bsModalRef = this.modalService.show(LogoutDialogComponent, {class: 'modal-dialog-centered'});
  }
}
