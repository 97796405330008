import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorHandlerModule} from './errors/error-handler.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ErrorHandlerModule]
})
export class CoreModule {
}
