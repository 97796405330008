import {INavData} from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Tableau de bord',
    url: 'dashboard',
    icon: 'icon-speedometer'
  },
  {
    title: true,
    name: 'Mes activités'
  },
  {
    name: 'Mes Tâches',
    url: 'taches',
    icon: 'fa fa-tasks'
  },
  {
    name: 'Mes Documents',
    url: 'documents',
    icon: 'fa fa-file-pdf-o'
  }, {
    name: 'Mes Workspaces',
    url: 'myworkspaces',
    icon: 'fa fa-briefcase'
  }, {
    name: 'Mes favoris',
    url: 'favoris',
    icon: 'fa fa-star-o'
  },
  {
    title: true,
    name: 'Gestion'
  },
  {
    name: 'Workflow',
    url: 'workflow',
    icon: 'fa fa-code-fork'
  },
  {
    name: 'Notifications',
    url: 'notifications',
    icon: 'fa fa-bell-o'
  },
  {
    title: true,
    name: 'Configuration'
  },
  {
    name: 'Préférences',
    url: 'preference',
    icon: 'fa fa-sliders'
  },
  {
    name: 'Utilisateurs',
    url: 'users',
    icon: 'fa fa-user-o'
  },
  {
    name: 'Groupes',
    url: 'groupes',
    icon: 'fa fa-users'
  },
  {
    name: 'Workspaces',
    url: 'workspaces',
    icon: 'fa fa-wpforms'
  },
  {
    name: 'Schemas',
    url: 'schemas',
    icon: 'fa fa-bar-chart-o'
  },
  {
    name: 'Tags',
    url: 'tags',
    icon: 'fa fa-tags'
  },
  /* {
    title: true,
    name: 'Paramètres',
  } */
];
