import {Component, OnInit} from '@angular/core';
import {navItems} from '../../_nav';
import {Router} from '@angular/router';
import {LogoutDialogService} from '../../shared/confirmLogout/logout-dialog.service';
import {CognitoService} from '../../authentication-services/cognito.service';
import {Auth} from 'aws-amplify';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  today: Date = new Date();
  usersInfos: any;

  constructor(private router: Router,
              private logoutService: LogoutDialogService,
              private cognitoService: CognitoService) {
  }

  isAuth = false;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  ngOnInit(): void {
    this.getCurrentUserInfos().then(data => {
      this.usersInfos = data;
      this.cognitoService.setUserInfos(data);
    });
  }

  onLogout() {
    this.logoutService.openLogoutDialog();
  }

  redirectToProfile() {
    this.router.navigateByUrl('profil');
  }

  async getCurrentUserInfos() {
    return await Auth.currentAuthenticatedUser();
  }

}
