import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './spinner/spinner.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SpinnerOverlayComponent} from './spinner-overlay/spinner-overlay.component';


@NgModule({
  declarations: [SpinnerComponent, SpinnerOverlayComponent],
  imports: [
    CommonModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ModalModule.forRoot(),
    CoreModule,
    ToastrModule.forRoot(),
    FormsModule,
    CollapseModule,
    PaginationModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    CommonModule,
    AmplifyUIAngularModule,
    BsDropdownModule,
    TabsModule,
    ChartsModule,
    ModalModule,
    CoreModule,
    ToastrModule,
    FormsModule,
    CollapseModule,
    PaginationModule,
    TypeaheadModule,
    TooltipModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule
  ],
  entryComponents: [SpinnerOverlayComponent]
})
export class SharedModule {
}
