import {DoBootstrap, LOCALE_ID, NgModule} from '@angular/core';
import Interactions from '@aws-amplify/interactions';
import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import localeFr from '@angular/common/locales/fr';
// Import routing module
// Import 3rd party components
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NoConnectionComponent} from './shared/sahred-components/no-connection/no-connection.component';
import {ErrorDialogComponent} from './shared/errors/error-dialog/error-dialog.component';
import {LogoutDialogComponent} from './shared/confirmLogout/error-dialog/logout-dialog.component';
import {AmplifyModules, AmplifyService} from 'aws-amplify-angular';
import Amplify, {Auth} from 'aws-amplify';
import {LoginComponent} from './login/login.component';
import {PasswordPatternDirective} from './shared/directives/password-pattern.directive';
import {ValidateUserNameDirective} from './shared/directives/validate-user-name.directive';
import {MatchPasswordDirective} from './shared/directives/match-password.directive';
import {environment} from '../environments/environment';
import {RequestInterceptorService} from './authentication-services/request-interceptor.service';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app.routing';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';
import {AmplifyUIAngularModule} from '@aws-amplify/ui-angular';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayModule} from '@angular/cdk/overlay';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

registerLocaleData(localeFr, 'fr');

@NgModule({
  imports: [
    AppRoutingModule,
    OverlayModule,
    SharedModule,
    HttpClientModule,
    AmplifyUIAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule
  ],
  declarations: [
    ValidateUserNameDirective,
    MatchPasswordDirective,
    PasswordPatternDirective,
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    NoConnectionComponent,
    ErrorDialogComponent,
    LogoutDialogComponent,
    LoginComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        });
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr'
    }
  ],
  entryComponents: [AppComponent, ErrorDialogComponent, LogoutDialogComponent],
  // bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap {
  ngDoBootstrap(appRef: import('@angular/core').ApplicationRef): void {
    try {
      console.log('init.....');
      Amplify.configure(environment.amplify.Auth);
      Auth.configure(environment.amplify.Auth);
      appRef.bootstrap(AppComponent);
    } catch (e) {
      console.log('error init....');
    }
  }
}


