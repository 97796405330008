import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpEvent} from '@angular/common/http';
import {catchError, switchMap} from 'rxjs/operators';
import {from, interval, Observable, Subscription} from 'rxjs';
import {Auth} from 'aws-amplify';
import {CognitoService} from './cognito.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor, OnInit, OnDestroy {

  refreshTokenTime: number = 50 * (60 * 1000);
  subscription: Subscription;
  tokenExpTime = interval(this.refreshTokenTime);
  access_token: string = null;
  cognitoUser;

  constructor() {
    this.subscription = this.tokenExpTime.subscribe(val => {
      this.refreshTokens();
    });
  }

  ngOnInit(): void {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession())
      .pipe(
        switchMap((auth: any) => {
          const access_token = auth.accessToken.jwtToken;
          const id_token = auth.idToken.jwtToken;
          request = this.addToken(request, access_token, id_token);
          return next.handle(request);
        }),
        catchError((error) => {
          console.log('RequestInterceptorService in => ', error.message);
          return next.handle(request);
        })
      );
  }

  private addToken(request: HttpRequest<any>, token: string, idToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      }
    });
  }

  async refreshTokens() {
    try {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      this.cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        const { idToken, refreshToken, accessToken } = session;
        this.access_token = accessToken.jwtToken;
        console.log(this.access_token);
      });
    } catch (e) {
      // console.log('Unable to refresh Token', e);
    }
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
