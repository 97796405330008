import {Component, OnInit, OnDestroy, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Auth} from 'aws-amplify';
import {AmplifyService} from 'aws-amplify-angular';
import {Logger} from 'aws-amplify';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CognitoService} from '../authentication-services/cognito.service';
import {NotificationService} from '../shared/services/notification.service';
import {Credential} from './models/Credential';
import {ResetPwd} from './models/ResetPwd';
import {ForgotPwd} from './models/ForgotPwd';
import {SpinnerOverlayService} from '../shared/services/spinner-overlay.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  logger = new Logger('LOG', 'ERROR');
  modalRef: BsModalRef;
  nowDate = new Date();
  subscription: Subscription;
  invalide: Boolean = false;
  showAlert: Boolean = false;
  userName: Boolean = false;
  notAuthMessage: string;
  showAlertError: Boolean = false;
  connected: Boolean = false;
  ErrorMessage = '';
  SuccessMessage = '';
  loading: Boolean = false;
  _loading: Boolean = false;
  credentials = new Credential();
  resetPassword = new ResetPwd();
  forgotPassword = new ForgotPwd();

  user: any = {};

  constructor(private authenticationService: CognitoService,
              private router: Router,
              private amplifyService: AmplifyService,
              private modalService: BsModalService,
              private spinnerService: SpinnerOverlayService,
              private notificationService: NotificationService) {
    this.amplifyService.storage();
  }

  ngOnInit() {
    if (this.authenticationService.isAuth) {
      this.redirectToHome();
    }
  }

  get isAuth() {
    return this.authenticationService.isAuth;
  }

  redirectToHome() {
    this.router.navigateByUrl('ecm/dashboard');
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async login(credentials) {
    try {
      this.spinnerService.show();
      this.user = await Auth.signIn(credentials.username, credentials.password);
      this.invalide = false;
      localStorage.setItem('show', 'grid');
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED' && this.user.signInUserSession === null) {
        this.connected = true;
      } else {
        this.authenticationService.isAuth = true;
        this.redirectToHome();
      }
      this.spinnerService.hide();
    } catch (error) {
      this.notificationService.showError('Le couple nom d\'utilisateur - mot de passe est invalide.', 'Error !');
      this.spinnerService.hide();
    }
  }

  async resetPass(resetPassword) {
    try {
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.spinnerService.show();
        // the array of required attributes, e.g ['email', 'phone_number']
        const {requiredAttributes} = this.user.challengeParam;
        Auth.completeNewPassword(
          this.user,
          resetPassword.newPassword,
          {
            email: this.user.challengeParam.userAttributes.email,
            phone_number: this.user.challengeParam.userAttributes.phone_number,
            name: this.user.username,
            family_name: this.user.username,
            locale: this.nowDate.toLocaleString(),
            zoneinfo: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        ).then(user => {
          this.authenticationService.isAuth = true;
          this.redirectToHome();
        }).catch(error => {
          this.spinnerService.hide();
          this.logger.debug('callback error : ', error);
          this.notificationService.showError(error.message, 'Error !');
        });
      } else {
        // other situations
      }
    } catch (error) {
      this.notificationService.showError(error.message, 'Error !');
      this.spinnerService.hide();
      this.logger.debug('callback error : ', error);
    }
  }

  onForgetPassword() {
    this.spinnerService.show();
    Auth.forgotPassword(this.forgotPassword.username)
      .then(data => {
        this.userName = true;
        this.spinnerService.hide();
        this.showAlertError = false;
      })
      .catch(err => {
        this.spinnerService.hide();
        if (err.code === 'LimitExceededException') {
          this.showAlertError = true;
          this.ErrorMessage = 'Vous avez dépassé la limitation, veuillez réessayer ultérieurement';
        }
      });
  }

  onChangePassword() {
    this.spinnerService.show();
    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(this.forgotPassword.username, this.forgotPassword.code, this.forgotPassword.newPassword)
      .then(data => {
        this.showAlert = true;
        this.SuccessMessage = 'Votre mot de passe a été changé, veuillez connecter avec le nouveau mot de passe';
        this._loading = false;
        this.userName = false;
        this.modalRef.hide();
      })
      .catch(err => {
        this.showAlertError = true;
        this.ErrorMessage = 'Votre code est invalide, veuillez entrer un code valide';
        this.spinnerService.hide();
      });
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
      this.logger.debug('unsubscription ok ');
    }
  }
}

