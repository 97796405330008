import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CognitoService} from '../../../authentication-services/cognito.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent implements OnInit {
  constructor(private router: Router, public bsModalRef: BsModalRef, private cognitoService: CognitoService) {
  }

  ngOnInit() {

  }

  onLogout() {
    this.cognitoService.signOut().then(r => {
      console.log('Déconnexion....');
    });
  }

}
