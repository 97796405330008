<div class="modal-header text-muted">
  <h5 class="modal-title m-auto">Voulez vous vraiment quitter ?</h5>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <button class="btn btn-light btn-block"
              (click)="bsModalRef.hide()">
        Annuler
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-primary btn-block"
              (click)="onLogout();bsModalRef.hide()">
        Confirmer
      </button>
    </div>
  </div>
</div>
