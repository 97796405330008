import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {NoConnectionComponent} from './shared/sahred-components/no-connection/no-connection.component';
import {NetworkguardService} from './shared/services/networkguard.service';
import {LoginComponent} from './login/login.component';
import {AuthGuardService} from './authentication-services/auth-guard.service';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NetworkguardService]
  }, {
    path: '',
    redirectTo: 'ecm/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'noConnection',
    component: NoConnectionComponent
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'ecm',
    canActivate: [AuthGuardService, NetworkguardService],
    component: DefaultLayoutComponent,
    data: {
      title: 'Tableau de bord'
    },
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'groupes',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/groupes/groupes.module').then(m => m.GroupesModule)
      },
      {
        path: 'notifications',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'preference',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/preference/preference.module').then(m => m.PreferenceModule)
      },
      {
        path: 'profil',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'recherches',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/recherches/recherches.module').then(m => m.RecherchesModule)
      },
      {
        path: 'users',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'workflow',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/workflow/workflow.module').then(m => m.WorkflowModule)
      },
      {
        path: 'documents',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/documents/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'favoris',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/favoris/favoris.module').then(m => m.FavorisModule)
      },
      {
        path: 'taches',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/taches/taches.module').then(m => m.TachesModule)
      },
      {
        path: 'workspaces',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/workspaces/workspaces.module').then(m => m.WorkspacesModule)
      },
      {
        path: 'myworkspaces',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/my-workspace/my-workspace.module').then(m => m.MyWorkspaceModule)
      },
      {
        path: 'tags',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/tags/tags.module').then(m => m.TagsModule)
      },
      {
        path: 'schemas',
        canActivate: [AuthGuardService, NetworkguardService],
        loadChildren: () => import('./views/schemas/schemas.module').then(m => m.SchemasModule)
      }
    ]
  },
  {path: '**', redirectTo: 'ecm/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
