import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {
  title: string;
  closeBtnName: string;
  errorCode: number;
  message: string;
  errMessage: string;

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {

  }
}
